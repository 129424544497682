import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 300,
        backgroundColor: 'white',
    },
    tableRow: {
        padding: '4px',
        paddingLeft: '16px'
    },
});

const MetadataTable = (props) => {
    const classes = useStyles();

    const _createRows = (meta) => {
        const revMeta = [];
        Object.keys(meta).forEach(key => {
            if (key === 'breachFailureTime' || key === 'breachHeightPercentage'
                || key === 'duration' || key === 'breachSideSlope' || key === 'reservoirDepth'
                || key === 'reservoirVolume' || key === 'termBreachWidth') {
                const row = {}
                row[key] = meta[key].toFixed(2);
                revMeta.push(row);
            }
        });
        return revMeta;
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="metadata table">
                <TableHead>
                    <TableRow>
                        <TableCell>Parameter</TableCell>
                        <TableCell>Scenario</TableCell>
                        {/* <TableCell>{props['orchestrationID']}</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_createRows(props).map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell className={classes.tableRow}>
                                {Object.keys(row)[0]
                                .replace(/([A-Z])/g, ' $1')
                                .replace(/^./, function(str){ return str.toUpperCase();})}
                            </TableCell>
                            <TableCell className={classes.tableRow}>{row[Object.keys(row)]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default MetadataTable;
export const satelliteStyle = {
    url: 'mapbox://styles/mapbox/satellite-v9',
    id: 'mbsat_1',
    name: 'Satellite',
    label: 'Satellite',
    icon: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoiYmlndmlraW5nbWoiLCJhIjoiY2sxMHhhenN4MGFuZzNkb2l3dHNwd2YybyJ9.gQoQyxLUPVXhuWYd6SeKlQ&logo=false&attribution=false',
    style: {
        version: 8,
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [{
            id: 'background',
            paint: {
                'background-color': 'rgb(4,7,14)'
            },
            type: 'background'
        }, {
            id: 'satellite',
            source: 'mapbox',
            'source-layer': 'mapbox_satellite_full',
            type: 'raster'
        }],
        metadata: {
            'mapbox:autocomposite': true,
            'mapbox:type': 'default'
        },
        name: 'Satellite',
        sources: {
            mapbox: {
                tileSize: 256,
                type: 'raster',
                url: 'mapbox://mapbox.satellite'
            }
        },
        sprite: 'mapbox://sprites/mapbox/satellite-v9'
    },
    layerGroups: []
}
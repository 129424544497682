import axios from 'axios';
import pnnlConfig from '../config/default';

const apiAxios = axios.create();

apiAxios.interceptors.request.use((config) => {
    config.headers['Ocp-Apim-Subscription-Key'] = pnnlConfig.SUBSCRIPTION;
    return config;
});

export default apiAxios;
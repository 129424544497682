import { createAction, handleActions } from 'redux-actions';
import KeplerGlSchema from 'kepler.gl/schemas';
import {
	RECEIVE_COMBINED_RESULTS,
	RECEIVE_GEOJSON 
} from '../actions';

// CONSTANTS
export const INIT = 'INIT';
export const SET_MAP_CONFIG = 'SET_MAP_CONFIG';

// ACTIONS
export const appInit = createAction(INIT);
export const setMapConfig = createAction(SET_MAP_CONFIG);

const initialState = {
	appName: 'beltrami',
	loaded: false
}

// REDUCER
const appReducer = handleActions({
	[INIT]: (state, action) => ({
		...state,
		loaded: true
	}),
	[SET_MAP_CONFIG]: (state, action) => ({
		...state,
		mapConfig: KeplerGlSchema.getConfigToSave(action.payload)
	}),  
	[RECEIVE_COMBINED_RESULTS]: (state, action) => ({
		...state,
		geojsonResults: action.geojsonResults,
		metadata: action.metadata,
		sas: action.sas
	  }),
  	[RECEIVE_GEOJSON]: (state, action) => ({
		...state,
		dataset: action.dataset
  	})
}, initialState);

export default appReducer;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import pnnlConfig from '../config/default';

const MapContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

class Search extends Component {
    // componentDidMount() {
        // this.lng = -98.7;//.5795;
        // this.lat = 39.8283;
        // this.zoom = 4;
        // mapboxgl.accessToken = pnnlConfig.MAPBOX;
        // const map = new mapboxgl.Map({
        //     container: this.mapContainer,
        //     style: 'mapbox://styles/mapbox/dark-v10',
        //     center: [this.lng, this.lat],
        //     zoom: this.zoom
        // });
        // // TODO: replace with dam data move to action/reducer 
        // map.on('load', function () {
        //     map.addSource('earthquakes', {
        //         'type': 'geojson',
        //         'data':
        //             'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson'
        //     });
        //     map.addLayer({
        //         'id': 'earthquake-heat',
        //         'type': 'heatmap',
        //         'source': 'earthquakes',
        //         'maxzoom': 9,
        //         'paint': {
        //             // Increase the heatmap weight based on frequency and property magnitude
        //             'heatmap-weight': [
        //                 'interpolate',
        //                 ['linear'],
        //                 ['get', 'mag'],
        //                 0,
        //                 0,
        //                 6,
        //                 1
        //             ],
        //             // Increase the heatmap color weight weight by zoom level
        //             // heatmap-intensity is a multiplier on top of heatmap-weight
        //             'heatmap-intensity': [
        //                 'interpolate',
        //                 ['linear'],
        //                 ['zoom'],
        //                 0,
        //                 1,
        //                 9,
        //                 3
        //             ],
        //             // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        //             // Begin color ramp at 0-stop with a 0-transparancy color
        //             // to create a blur-like effect.
        //             'heatmap-color': [
        //                 'interpolate',
        //                 ['linear'],
        //                 ['heatmap-density'],
        //                 0,
        //                 'rgba(33,102,172,0)',
        //                 0.2,
        //                 'rgb(103,169,207)',
        //                 0.4,
        //                 'rgb(209,229,240)',
        //                 0.6,
        //                 'rgb(253,219,199)',
        //                 0.8,
        //                 'rgb(239,138,98)',
        //                 1,
        //                 'rgb(178,24,43)'
        //             ],
        //             // Adjust the heatmap radius by zoom level
        //             'heatmap-radius': [
        //                 'interpolate',
        //                 ['linear'],
        //                 ['zoom'],
        //                 0,
        //                 2,
        //                 9,
        //                 20
        //             ],
        //             // Transition from heatmap to circle layer by zoom level
        //             'heatmap-opacity': [
        //                 'interpolate',
        //                 ['linear'],
        //                 ['zoom'],
        //                 7,
        //                 1,
        //                 9,
        //                 0
        //             ]
        //         }
        //     },
        //         'waterway-label');
        //     map.addLayer(
        //         {
        //             'id': 'earthquakes-point',
        //             'type': 'circle',
        //             'source': 'earthquakes',
        //             'minzoom': 7,
        //             'paint': {
        //                 // Size circle radius by earthquake magnitude and zoom level
        //                 'circle-radius': [
        //                     'interpolate',
        //                     ['linear'],
        //                     ['zoom'],
        //                     7,
        //                     ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
        //                     16,
        //                     ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50]
        //                 ],
        //                 // Color circle by earthquake magnitude
        //                 'circle-color': [
        //                     'interpolate',
        //                     ['linear'],
        //                     ['get', 'mag'],
        //                     1,
        //                     'rgba(33,102,172,0)',
        //                     2,
        //                     'rgb(103,169,207)',
        //                     3,
        //                     'rgb(209,229,240)',
        //                     4,
        //                     'rgb(253,219,199)',
        //                     5,
        //                     'rgb(239,138,98)',
        //                     6,
        //                     'rgb(178,24,43)'
        //                 ],
        //                 'circle-stroke-color': 'white',
        //                 'circle-stroke-width': 1,
        //                 // Transition from heatmap to circle layer by zoom level
        //                 'circle-opacity': [
        //                     'interpolate',
        //                     ['linear'],
        //                     ['zoom'],
        //                     7,
        //                     0,
        //                     8,
        //                     1
        //                 ]
        //             }
        //         },
        //         'waterway-label'
        //     );
        // });
    // }

    render() {
        return (
            <div style={{ height: "calc(100% - 64px)", marginTop: '64px' }}>
                {/* <div align="center">
                    <FontAwesomeIcon icon={faHardHat} size={"6x"}></FontAwesomeIcon>
                    <p>This page is currently under construction!</p>
                    <p>{this.props.location.pathname}</p>
                </div> */}

                {/* <MapContainer ref={el => this.mapContainer = el} /> */}

                {/* <iframe src="http://kibana.howstuffbreaks.org/goto/bb6d1345c71fdb224e7403e408f4e30d?embed=true" height="600" width="100%"></iframe> */}
                <iframe title="Map" src="http://kibana.howstuffbreaks.org/app/maps#/map/a1f04de0-32f8-11ea-a88a-612a619264aa?embed=true&_g=()" height="90%" width="100%"></iframe>
            </div>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(Search);
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';


// TODO: Uncomment on APIM setup  /////////////////////
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
	require('./App');
}, DO_NOT_LOGIN);
///////////////////////////////////////////////////////


const Root = () => (
	<Provider store={store}>
		<App></App>
	</Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

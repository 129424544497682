import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    AppBar, Toolbar, Typography, Paper, Tabs, Tab
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';


const styles = theme => ({
    root: {
        flexGrow: '1'
    },
    main: {
        height: '100vh'
    },
    tabMenu: {
        textAlign: 'center'
    }
});

class Layout extends Component {
    state = {
        value: 'search',
        viz: '/visualize'
    };

    render() {
        // const { classes, location: { pathname }, children } = this.props;
        const { classes, children } = this.props;

        const handleChange = (event, newValue) => {
            this.props.history.push(newValue);
        }

        return (
            <div className={classes.root}>
                <AppBar color="dark" position="fixed">
                    <Toolbar>
                        <Typography variant="h6" color="inherit">WELL</Typography>
                        {/* <Tabs value={this.props.history.location.pathname} onChange={handleChange} className={classes.tabMenu}>
                            <Tab
                                label="Execute"
                                value="/execute"/>
                            <Tab
                                label="Search"
                                value="/search"/>
                            <Tab
                                label="Visualize"
                                value="/visualize"/>
                        </Tabs> */}
                    </Toolbar>
                </AppBar>
                <Paper className={classes.main}>
                    <main className={classes.main}>
                        {children}
                    </main>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export default compose(
    withRouter,
    withStyles(styles),
    connect(
        mapStateToProps,
        dispatchToProps
    ),
)(Layout);
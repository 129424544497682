import React, { Component } from 'react';
import { connect } from 'react-redux';

import Search from './pages/search-page';
import NotFound from './pages/not-found';
import Visualize from './pages/visualize-page';
import Execute from './pages/execute-page';
import Layout from './pages/layout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import './App.css';

const muiTheme = createMuiTheme({
    palette: {
        type: 'dark'
    },
    overrides: {
        MuiPaper: {
            root:{
                backgroundColor: '#3996A0'
            }
        },
        MuiTableCell: {
            head: {
                color: 'black'
            },
            body: {
                color: 'black',
            }
        }
    }
});

class App extends Component {
    state = {}

    render() {
        return (
            <BrowserRouter>
                <MuiThemeProvider theme={muiTheme}>
                    <Layout>
                        <Switch>
                            <Redirect exact from="/" to="/visualize" />
                            <Route path='/visualize' component={Visualize} />
                            <Route path='/search' component={Search} />
                            <Route path='/execute' component={Execute} />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </Layout>
                </MuiThemeProvider>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(App);

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

class NotFound extends Component {
    render() {
        return (
            <div align="center" style={{height: "calc(100% - 64px)", marginTop: '64px'}}>
                <FontAwesomeIcon icon={faExclamationTriangle} size={"2x"}></FontAwesomeIcon>
                <h2>
                    404 - PAGE NOT FOUND
                </h2>
            </div>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(NotFound);
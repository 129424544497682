import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight,
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
    injectComponents
} from 'kepler.gl/components';
import {
    reorderLayer, layerVisConfigChange, addCustomMapStyle, inputMapStyle, layerConfigChange, 
    updateMap, forwardTo, toggleSidePanel
} from 'kepler.gl/actions';
import { theme } from 'kepler.gl/styles';
// import { Icons } from 'kepler.gl/components';
import { addDataToMap } from 'kepler.gl/actions';
import queryString from 'query-string';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import styled from 'styled-components';
import {
    Grid, ButtonGroup, Button
} from '@material-ui/core';

// Local Javascript Libraries
import MetadataTable from '../components/metadata-table';
import pnnlConfig from '../config/default';
import {
    fetchCombinedResults, fetchGeoJson
} from '../actions';
import { satelliteStyle } from '../models/satellite-style-model';

const API = pnnlConfig.API_URI;

const StyledMapConfigDisplay = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 10px;
  right: 10px;
  background-color: #FFFFFF; //${theme.sidePanelBg};
  font-size: 11px;
  width: 350px;
  color: #202020; //${theme.textColor};
  word-wrap: break-word;
  min-height: 60px;
  padding: 10px;
`;

const ScenarioBtn = styled.button`
	float: right;
	color: #A0A7B4;
	background: none;
	border: none;
	height: 20px;
	&:hover {
		background: #535a62;
	}
	&:disabled {
		background: #242730;
		color: #6A7485;
	}
`;

const KeplerGl = injectComponents([
]);

class Visualize extends Component {
    isRightDisabled = true;
    isLeftDisabled = true;
    areMultiple = false;
    API_URL = '';
    activeLayer = 'arrival';
    // orchestrationId = props.match.params;

    componentWillMount() {
        if (!localStorage.getItem('damUrl') || localStorage.getItem('damUrl') === '') {
            localStorage.setItem('damurl', this.props.location.search);
        }
    }

    componentDidMount() {
        this.API_URL = API + "create/rift/results/";
        const values = queryString.parse(localStorage.getItem('damurl'))
        if (Object.entries(values).length !== 0 && ('orchestrationIds' in values)) {
            const orchIds = values.orchestrationIds;
            this.orchestrationId = orchIds.split(',');
            // TODO: This should be updated to a filter
            if (this.orchestrationId.length > 1) {
                this.areMultiple = true;
                this.isRightDisabled = false;
                this.orchestrationId = this.orchestrationId[0];
            } else {
                this.orchestrationId = this.orchestrationId[0];
            }
            this._loadCombinedResults(this.API_URL + this.orchestrationId);
        }
        this.props.dispatch(toggleSidePanel(null));
        this.props.dispatch(inputMapStyle(satelliteStyle));
        this.props.dispatch(addCustomMapStyle());
    }

    _reorderLayers = () => {
        const newOrder = [];
        const layers = [];
        this.props.keplerGl.beltramiMap.visState.layers.forEach((e, i) => {
            let layer = {};
            let cnt = 2;
            // TODO: these need to use the function I created below
            // TODO: the filter string should come from the output from the action
            if (e.config.label.indexOf('Dam Site Selection') !== -1) {
                layer = {
                    'oldIndex': i,
                    'newIndex': 0
                }
            } else if (e.config.label.indexOf('Local Dem Tile') !== -1) {
                layer = {
                    'oldIndex': i,
                    'newIndex': 1
                }
            } else {
                layer = {
                    'oldIndex': i,
                    'newIndex': cnt
                }
                cnt += 1;
            }
            layers.push(layer);
        });
        layers.sort((a, b) => a.newIndex - b.newIndex);
        layers.forEach(l => newOrder.push(l.oldIndex));
        this.props.dispatch(reorderLayer(newOrder));
        this.activeLayer = 'arrival';
    };

    _loadCombinedResults = (url) => {
        let cnt = 0;
        this.props.dispatch(fetchCombinedResults(url))
            .then(() => {
                if (Object.entries(this.props.app.geojsonResults).length !== 0) {
                    this.props.app.geojsonResults.forEach((geojson) => {
                        this.props.dispatch(fetchGeoJson(geojson))
                            .then(x => {
                                this.props.dispatch(addDataToMap(this.props.app.dataset)).then(() => {
                                    cnt += 1;
                                })
                                // TODO: This seems hacky and will cause issues in the future.
                                if (cnt === 3) {
                                    this._reorderLayers();
                                    this._initializeLayers();
                                    this._toggleArrival();
                                }
                            });
                    })
                }
            });
    };

    _popupVisible = () => {
        const layers = this.props.keplerGl.beltramiMap.visState.layers.filter(x => x.config.label === 'Dam Site Selection' || x.config.label === 'Local Dem Tile');
        layers.forEach(lay => {
            let layerConfig = lay.config;
            layerConfig.isVisible = !layerConfig.isVisible;
            this.props.dispatch(layerVisConfigChange(lay, layerConfig));
        })
    }

    _rightClick = () => {
        const orch = this.props.match.params.orchestration;
        const orchestrations = orch.split(',');
        // TODO: This needs to be passed in NEED a model to get the orchestrations available
        this.orchestrationId = orchestrations[1];
        if (orchestrations.indexOf(this.orchestrationId) === orchestrations.length - 1) {
            this.isRightDisabled = true;
            this.isLeftDisabled = false;
        }
    }

    _leftClick = () => {
        // TODO: These should probably be added to state at the beginning
        const orch = this.props.match.params.orchestration;
        const orchestrations = orch.split(',');
        // TODO: This needs to be passed in NEED a model to get the orchestrations available
        this.orchestrationId = orchestrations[0];
        if (orchestrations.indexOf(this.orchestrationId) === 0) {
            this.isRightDisabled = false;
            this.isLeftDisabled = true;
        }
    }

    // Returns a layer from the kepler object based on a label
    // TODO: robustness update this should be id, need to figure out how to return id
    _layerFinder = (layers, label) => {
        let layer = {};
        layer = layers.filter(l => l.config.label === label);
        return layer[0];
    }

    _toggleLayersStroke(layers) {
        layers.forEach(layer => layer.config.visConfig.stroked = !layer.config.visConfig.stroked);
    }

    _generateColorField = (name, index, type) => {
        return {
            format: "",
            id: name,
            name: name,
            tableFieldIndex: index,
            type: type
        }
    }

    _getArrayFromGjsonField = (dataToFeature, field) => {
        const field_array = [];
        dataToFeature.forEach(df => field_array.push(df.properties[field]));
        return field_array;
    }

    _createVisConfig = (colors, cat, name, type) => {
        return {
            category: cat,
            colors: colors,
            name: name,
            type: type
        }
    }

    _toggleStrokes = (arr_layer, depth_layer, dem_layer) => {
        this._toggleLayersStroke([arr_layer, depth_layer, dem_layer]);
    }

    _changeArrivalColor = (arr_layer) => {
        const new_arr_layer = arr_layer;
        new_arr_layer.config.colorField = this._generateColorField("arrival_mi", 2, "integer");
        new_arr_layer.config.colorDomain = this._getArrayFromGjsonField(arr_layer.dataToFeature, 'arrival_mi')
            .sort((a, b) => { return a - b });
        new_arr_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850'], 'ColorBrewer', 'ColorBrewer RdYlGn-6', 'diverging'
        );
        this.props.dispatch(layerConfigChange(arr_layer, new_arr_layer.config));
    }

    _changeDepthColor = (depth_layer) => {
        const new_depth_layer = depth_layer;
        new_depth_layer.config.colorField = this._generateColorField("depth_ft", 2, "integer");
        new_depth_layer.config.colorDomain = this._getArrayFromGjsonField(depth_layer.dataToFeature, 'depth_ft')
            .sort((a, b) => { return a - b });
        new_depth_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#2b8cbe', '#045a8d'], 'ColorBrewer', 'ColorBrewer PuBu-6', 'sequential'
        );
        this.props.dispatch(layerConfigChange(depth_layer, new_depth_layer.config));
    }

    _changeDemColor = (dem_layer) => {
        const new_dem_layer = dem_layer;
        new_dem_layer.config.colorField = this._generateColorField("height_feet", 3, "real");
        new_dem_layer.config.colorDomain = this._getArrayFromGjsonField(dem_layer.dataToFeature, 'height_feet')
            .sort((a, b) => { return a - b });
        new_dem_layer.config.visConfig.colorRange = this._createVisConfig(
            ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'], 'ColorBrewer', 'ColorBrewer Greys-6', 'singlehue'
        );
        this.props.dispatch(layerConfigChange(dem_layer, new_dem_layer.config));
    }

    _updateMapZoom = () => {
        const mapState = this.props.keplerGl.beltramiMap.mapState;
        const newZoom = mapState.zoom - 1;
        this.props.keplerGlDispatch(updateMap({ zoom: newZoom }));
    }

    _initializeLayers = () => {
        const layers = this.props.keplerGl.beltramiMap.visState.layers;
        const arr_layer = this._layerFinder(layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(layers, 'Depth Geojson Data');
        const dem_layer = this._layerFinder(layers, 'Local Dem Tile');

        // Hide on init per Dave J. Comments (11/22/19)
        dem_layer.config.isVisible = false;

        this._toggleStrokes(arr_layer, depth_layer, dem_layer);
        // TODO: Should these be combined?
        this._changeArrivalColor(arr_layer);
        this._changeDepthColor(depth_layer);
        this._changeDemColor(dem_layer);

        this._updateMapZoom();
    }

    _toggleArrival = () => {
        const arr_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Depth Geojson Data');
        arr_layer.config.isVisible = true;
        depth_layer.config.isVisible = false;
        arr_layer.config.visConfig.opacity = 0.3;
        this.props.dispatch(layerConfigChange(arr_layer, arr_layer.config));
        this.props.dispatch(layerConfigChange(depth_layer, depth_layer.config));
    }

    _toggleDepth = () => {
        const arr_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Arrival Geojson Data');
        const depth_layer = this._layerFinder(this.props.keplerGl.beltramiMap.visState.layers, 'Depth Geojson Data');
        arr_layer.config.isVisible = false;
        depth_layer.config.isVisible = true;
        depth_layer.config.visConfig.opacity = 0.3;
        this.props.dispatch(layerConfigChange(arr_layer, arr_layer.config));
        this.props.dispatch(layerConfigChange(depth_layer, depth_layer.config));
    }

    _handleLayers = (newLayer) => {
        if (newLayer === 'arrival') {
            if (this.activeLayer !== 'arrival') {
                this.activeLayer = 'arrival';
                this._toggleArrival();
            }
        } else {
            if (this.activeLayer !== 'depth') {
                this.activeLayer = 'depth';
                this._toggleDepth();
            }
        }
    }

    render() {
        return (
            <div style={{ position: 'inherit', width: '100%', height: 'calc(100% - 64px)', marginTop: '64px' }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <KeplerGl
                            mapboxApiAccessToken={pnnlConfig.MAPBOX}
                            id="beltramiMap"
                            width={width}
                            height={height}
                            theme="light"
                        />
                    )}
                </AutoSizer>
                <StyledMapConfigDisplay>
                    {this.props.app.metadata ?
                        <div>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12}>
                                    {this.areMultiple ?
                                        <span>
                                            <ScenarioBtn key="1" disabled={this.isRightDisabled} onClick={this._rightClick}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </ScenarioBtn>
                                            <ScenarioBtn key="2" disabled={this.isLeftDisabled} onClick={this._leftClick}>
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </ScenarioBtn>
                                        </span> : null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <h3>{this.props.app.metadata['nidInput']['nidid']} - {this.props.app.metadata['nidInput']['damName']}</h3>
                                </Grid>
                                <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
                                    <ButtonGroup color="primary" size="small">
                                        <Button
                                            className={this.activeLayer === 'arrival' ? 'active' : ''}
                                            onClick={this._handleLayers.bind(this, 'arrival')}
                                        >
                                            Arrival
                                        </Button>
                                        <Button
                                            className={this.activeLayer === 'depth' ? 'active' : ''}
                                            onClick={this._handleLayers.bind(this, 'depth')}
                                        >
                                            Depth
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                    <h3>METADATA</h3>
                                    <MetadataTable {...this.props.app.metadata}></MetadataTable>
                                </Grid>
                            </Grid>
                        </div> :
                        'No Metadata Loaded'
                    }
                </StyledMapConfigDisplay>
            </div>
        );
    }
}

const mapStateToProps = state => { return { ...state } };
const dispatchToProps = (dispatch, props) => ({
    dispatch,
    keplerGlDispatch: forwardTo("beltramiMap", dispatch)
});

export default connect(mapStateToProps, dispatchToProps)(Visualize);
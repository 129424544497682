import {createAction} from 'redux-actions';
import {processGeojson} from 'kepler.gl/processors';
import apiAxios from './interceptors/subscriptionHeader';

export const REQUEST_COMBINED_RESULTS = 'REQUEST_COMBINED_RESULTS';
export const RECEIVE_COMBINED_RESULTS = 'RECEIVE_COMBINED_RESULTS';
export const REQUEST_GEOJSON = 'REQUEST_GEOJSON';
export const RECEIVE_GEOJSON = 'RECEIVE_GEOJSON';

export const setMapConfig = createAction(
	'SET_MAP_CONFIG',
	payload => payload
);

/**
 * this method loads the geojson
 * @param {string} url the string url for requesting a geojson
 * @returns {{type: string, url: string}}
 */
export function requestGeoJson(url) {
  return {
    type: REQUEST_GEOJSON,
    url
  };
}

/**
 * this method processes the response geojson
 * @param {object} fetchObj an object used to pass into the addDataToMap
 * @param {blob} json a json response object 
 * @returns {{type: string, dataset: object, receivedAt: Date}}
 */
export function receiveGeoJson(fetchObj, json) {
  fetchObj.geojson = json;
  const layer = _createLayer(fetchObj);
  return {
    type: RECEIVE_GEOJSON,
    dataset: layer,
    receivedAt: Date.now()
  };
}

export function fetchGeoJson(fetchObj) {
  return function (dispatch) {
    dispatch(requestGeoJson(fetchObj.url))
    return fetch(fetchObj.url)
      .then(
        response => response.json(),
        error => console.log('An error occured getting the geojson', error)
      )
      .then(json => {
          const data = processGeojson(json);
          return dispatch(receiveGeoJson(fetchObj, data))
      });
  }
}

function _createLayer(results) {
  return {
    datasets: {
      info: {
        label: results.label,
        id: results.id
      },
      data: results.geojson
    },
    option: {
      centerMap: true,
      readOnly: false
    }
  }
}

/**
 * this method requests the combined_results.json
 * @param {string} url the string url for requesting CombinedResults.json
 * @returns {{type: string, url: string}}
 */
export function requestCombinedResults(url) {
  return {
    type: REQUEST_COMBINED_RESULTS,
    url
  };
}

/**
 * this method loads the response of the geojson
 * @param {string} url the string url for requesting geojson
 * @param {object} json the json object returned from the fetch call 
 * @returns {{type: string, url: string, geojsonResults: object, receivedAt: Date}}
 */
export function receiveCombinedResults(url, json) {
  return {
    type: RECEIVE_COMBINED_RESULTS,
    url,
    geojsonResults: _mapGeojsonFiles(json),
    metadata: _mapMetadata(json),
    receivedAt: Date.now()
  };
}

function _isTypeGeoJson(meta) {
  return meta['type'] === 'GeoJson'
}

function _mapToFetchObj(jsonData) {
  return {
    url: jsonData.readUrl,
    label: jsonData.label,
    id: jsonData.hash
  };
}

// NOTE: This was added because the json file changed casing.
const _getCaseSensitiveKey = (json, key) =>  {
  const values = []
  Object.keys(json).forEach(k => { 
    if (k) {
      const lowerK = k.toLowerCase();
      const output={};
      output[lowerK] = k;
      values.push(output)
    } 
  });
  return values.filter(v => v[key])[0][key];
}

const _mapMetadata = (json) => {
  const metaprocess = _getCaseSensitiveKey(json, 'metaprocess');
  const modelhydrographparams = _getCaseSensitiveKey(json[metaprocess], 'modelhydrographparams');
  const damparameters = _getCaseSensitiveKey(json[metaprocess][modelhydrographparams], 'damparameters');
  return json[metaprocess][modelhydrographparams][damparameters];
}

function _mapGeojsonFiles(json) {
  const metaprocess = _getCaseSensitiveKey(json, 'metaprocess');
  const resultfiles = _getCaseSensitiveKey(json[metaprocess], 'resultfiles');
  // TODO: This needs to include a check to make sure it is the valid json object and return an error
  const jsonFiles = json[metaprocess][resultfiles].filter(_isTypeGeoJson);
  return jsonFiles.map(x => _mapToFetchObj(x));
}

export function fetchCombinedResults(url) {
  return function (dispatch) {
    dispatch(requestCombinedResults(url))

    return apiAxios.get(url)
      .then(
        response => response.data,
        error => console.log('An error occured getting combined_results.json', error)
      )
      .then(json =>
        dispatch(receiveCombinedResults(url, json))
      )
  }
}

import {
    REDIRECT_URI,
    API_URI,
    SUBSCRIPTION,
    MAPBOX
} from './constants';

const pnnlConfig = () => {
    const values = {
        [REDIRECT_URI]: {
            "dev": "http://localhost:3000/",
            "prod": "https://rift.howstuffbreaks.org/"
        },
        [API_URI]: {
            "dev": "https://nisac.azure-api.net/",
            "prod": "https://nisac.azure-api.net/"
        },
        [SUBSCRIPTION]: {
            "dev": "14a7f7c79c8d4cc88079c98675b6b87b",
            "prod": "14a7f7c79c8d4cc88079c98675b6b87b"
        },
        [MAPBOX]: {
            "dev": "pk.eyJ1IjoiYmlndmlraW5nbWoiLCJhIjoiY2sxMHhhenN4MGFuZzNkb2l3dHNwd2YybyJ9.gQoQyxLUPVXhuWYd6SeKlQ",
            // TODO: Prod token needs to be replaced
            "prod": "pk.eyJ1IjoiYmlndmlraW5nbWoiLCJhIjoiY2sxMHhhenN4MGFuZzNkb2l3dHNwd2YybyJ9.gQoQyxLUPVXhuWYd6SeKlQ",
        }
    }
    const valueForEnv = (key) => {
        if (window.location.hostname === 'localhost') {
            return values[key].dev;
        } else {
            return values[key].prod;
        }
    }

    const configObject = {
        "REDIRECT_URI": valueForEnv(REDIRECT_URI),
        "API_URI": valueForEnv(API_URI),
        "SUBSCRIPTION": valueForEnv(SUBSCRIPTION),
        "MAPBOX": valueForEnv(MAPBOX),
        "endpoints": {
            'https://nisac.azure-api.net': '1b7abe20-8c96-486b-8108-96dd3cbcea18'
        }
    }
    return configObject;
}

export default pnnlConfig();
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

class Execute extends Component {
    render () {
        return (
                <div align="center" style={{height: "calc(100% - 64px)", marginTop: '64px'}}>
                    <FontAwesomeIcon icon={faHardHat} size={"6x"}></FontAwesomeIcon>
                    <p>This page is currently under construction!</p>
                    <p>{this.props.location.pathname}</p>
                </div>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(Execute);
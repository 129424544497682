import {
  AuthenticationContext,
  adalFetch,
  withAdalLogin
} from 'react-adal';
import pnnlConfig from './config/default';

let redirectUri = pnnlConfig.REDIRECT_URI;

export const adalConfig = {
  tenant: 'HSBIP.onmicrosoft.com',
  clientId: '1ecfbbf9-23cf-446a-a99e-9d58c301c888',
  redirectUri,
  resource: 'https://nisac.azure-api.net',
  endpoints: pnnlConfig.endpoints,
  cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import keplerGlReducer from 'kepler.gl/reducers';
import {enhanceReduxMiddleware} from 'kepler.gl/middleware';
import appReducer from './reducers/app-reducer';
import thunk from 'redux-thunk';

const customizedKeplerGlReducer = keplerGlReducer
  .initialState({
    uiState: {
      // hide side panel when mounted
      activeSidePanel: 'layer',
      // hide all modals whtn mounted
      currentModal: null
    }
  });

const reducers = combineReducers({
  keplerGl: customizedKeplerGlReducer,
  app: appReducer
});

const middlewares = enhanceReduxMiddleware([
	thunk
]);

const enhancers = [applyMiddleware(...middlewares)];

const initialState = {};

export default createStore(
  reducers,
  initialState,
  compose(...enhancers)
);
